<template>
  <v-container>
    <h2>{{dietName}} Nutrient Analysis</h2>
    <v-alert type="error" v-if="error">{{ error }}</v-alert>
    <div v-else>
      <div class="caption">
        Showing analysis for the following meals
        <ul>
          <li v-for="meal of meals" v-bind:key="meal.id">
            {{ meal.id }} - {{ getDietName(meal.diet) }} - {{ meal.name }}
            (<a target="_blank" v-bind:href="`https://fed.fyi/#/chart/fedfedfed/${meal.id}`">open nutrient chart</a>)
          </li>
          <li v-if="selection.length>1">
            <a target="_blank" v-bind:href="`https://fed.fyi/#/charts/${meals.map(m=>m.id).join(',')}`">Combined chart
              for {{ meals.length }} meals</a> (not public, you need to be logged in on <a href="https://fed.fyi">https://fed.fyi</a>)
          </li>
        </ul>
      </div>
      <div class="caption pt-1" v-if="selection.length!==3">
        <span>Note: Targets and limits have been scaled by {{ (100 * (selection.length / 3)).toFixed() }}%.</span>
      </div>

      <v-data-table
          v-if="nutrients"
          :items="nutrients"
          :headers="headers"
          disable-pagination
          hide-default-footer
          dense
          :item-class="getRowClass"
          class="nutrient-table"
      >
        <template v-slot:item.amount="{ item }">
          {{ item.amount.toFixed(2) }} {{ item.unit }}
        </template>
        <template v-slot:item.percentage="{ item }">
          {{ item.percentage.toFixed(2) }} %
        </template>
        <template v-slot:item.problem="{ item }">
        <span v-if="item.problem && item.problem.lower_limit">
          lower limit of {{ item.problem.lower_limit.toFixed(2) }}{{ item.unit }}
        </span>
          <span v-if="item.problem && item.problem.underTarget">
          less than target {{ item.problem.underTarget.toFixed(2) }}{{ item.unit }}
        </span>
          <span v-if="item.problem && item.problem.upper_limit">
          upper limit of {{ item.problem.upper_limit.toFixed(2) }}{{ item.unit }}
        </span>
        </template>
        <template v-slot:item.target="{ item }">
          {{ item.target }}
        </template>
        <template v-slot:item.adjustment="{ item }">
          <span v-if="item.problem">
            {{ getAdjustmentRequired(item).toFixed() }}{{ item.unit }}
          </span>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import api from '@/api';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: "MealNutrientsMulti",
  data() {
    return {
      error: false,
      nutrients: null,
      meals: [],
      headers: [
        {text: 'Name', value: 'name',},
        {value: 'amount', text: 'Amount'},
        {value: 'percentage', text: 'RDA'},
        {value: 'problem', text: 'Problem'},
        {value: 'adjustment', text: 'Off by'}
        // {value: 'target'}
      ]
    }
  },
  mounted() {
    this.fetchData();

  },
  watch: {
    selection() {
      this.fetchData();
    }
  },
  computed: {
    ...mapGetters(['getDietName']),
    selection() {
      return this.$route.params.selection.split(',');
    },
    dietName() {
      /** this view presumes that all meals are the same diet */
      return this.meals.length > 0
          ? this.getDietName(this.meals[0].diet)
          : '';
    }
  },
  methods: {
    ...mapActions(['fetchMeal', 'fetchDiets']),

    async fetchData() {
      const mealIds = this.$route.params.selection.split(',');
      if (mealIds.length === 0) {
        this.error = 'No meals selected';
        return;
      }
      return Promise
          .all(mealIds.map(mealId => this.fetchMeal(mealId)))
          .then(meals => {
            this.meals = meals;
            let diet = meals[0].diet;
            if (meals.filter(meal => meal.diet === diet).length !== meals.length) {
              this.error = 'You can only show meals which belong to the same diet/stream';
              return;
            }
            api.get('meals/nutrients', {
              params: {
                mealIds: mealIds,
                dietId: diet
              }
            })
                .then(response => {
                  this.nutrients = response.data;
                })
          })
          .then(() => this.fetchDiets());
    },
    getAdjustmentRequired: function (item) {
      const {amount} = item;
      const {lower_limit, upper_limit, underTarget} = item.problem || {};
      let adjustmentNeeded = 0;
      if (lower_limit) {
        adjustmentNeeded = Math.max(adjustmentNeeded, lower_limit - amount);
      }
      if (upper_limit) {
        adjustmentNeeded = Math.max(adjustmentNeeded, amount - upper_limit);
      }
      if (underTarget) {
        adjustmentNeeded = Math.max(adjustmentNeeded, underTarget - amount);
      }
      return adjustmentNeeded;
    },
    getRowClass(item) {
      if (!item.problem) {
        return '';
      } else {
        const {amount} = item;
        const adjustmentNeeded = this.getAdjustmentRequired(item);
        const adjustmentPercent = Math.min(adjustmentNeeded, amount) / amount;
        const index = 5 - Math.round(adjustmentPercent * 4);
        return `nutrient_problem-${index}`;

      }
    }
  }
}
</script>

<style scoped>


>>> .nutrient_problem-1 {
  background-color: #EF5350;
  font-weight: bolder;
}

>>> .nutrient_problem-2 {
  background-color: #E57373;
  font-weight: bolder;
}

>>> .nutrient_problem-3 {
  background-color: #EF9A9A;
  font-weight: bolder;
}

>>> .nutrient_problem-4 {
  background-color: #FFCDD2;
  font-weight: bolder;
}

>>> .nutrient_problem-5 {
  background-color: #FFEBEE;
  font-weight: bolder;
}


</style>
