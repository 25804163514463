import { VAlert } from 'vuetify/lib/components/VAlert';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c('h2',[_vm._v(_vm._s(_vm.dietName)+" Nutrient Analysis")]),(_vm.error)?_c(VAlert,{attrs:{"type":"error"}},[_vm._v(_vm._s(_vm.error))]):_c('div',[_c('div',{staticClass:"caption"},[_vm._v(" Showing analysis for the following meals "),_c('ul',[_vm._l((_vm.meals),function(meal){return _c('li',{key:meal.id},[_vm._v(" "+_vm._s(meal.id)+" - "+_vm._s(_vm.getDietName(meal.diet))+" - "+_vm._s(meal.name)+" ("),_c('a',{attrs:{"target":"_blank","href":("https://fed.fyi/#/chart/fedfedfed/" + (meal.id))}},[_vm._v("open nutrient chart")]),_vm._v(") ")])}),(_vm.selection.length>1)?_c('li',[_c('a',{attrs:{"target":"_blank","href":("https://fed.fyi/#/charts/" + (_vm.meals.map(function (m){ return m.id; }).join(',')))}},[_vm._v("Combined chart for "+_vm._s(_vm.meals.length)+" meals")]),_vm._v(" (not public, you need to be logged in on "),_c('a',{attrs:{"href":"https://fed.fyi"}},[_vm._v("https://fed.fyi")]),_vm._v(") ")]):_vm._e()],2)]),(_vm.selection.length!==3)?_c('div',{staticClass:"caption pt-1"},[_c('span',[_vm._v("Note: Targets and limits have been scaled by "+_vm._s((100 * (_vm.selection.length / 3)).toFixed())+"%.")])]):_vm._e(),(_vm.nutrients)?_c(VDataTable,{staticClass:"nutrient-table",attrs:{"items":_vm.nutrients,"headers":_vm.headers,"disable-pagination":"","hide-default-footer":"","dense":"","item-class":_vm.getRowClass},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.amount.toFixed(2))+" "+_vm._s(item.unit)+" ")]}},{key:"item.percentage",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.percentage.toFixed(2))+" % ")]}},{key:"item.problem",fn:function(ref){
var item = ref.item;
return [(item.problem && item.problem.lower_limit)?_c('span',[_vm._v(" lower limit of "+_vm._s(item.problem.lower_limit.toFixed(2))+_vm._s(item.unit)+" ")]):_vm._e(),(item.problem && item.problem.underTarget)?_c('span',[_vm._v(" less than target "+_vm._s(item.problem.underTarget.toFixed(2))+_vm._s(item.unit)+" ")]):_vm._e(),(item.problem && item.problem.upper_limit)?_c('span',[_vm._v(" upper limit of "+_vm._s(item.problem.upper_limit.toFixed(2))+_vm._s(item.unit)+" ")]):_vm._e()]}},{key:"item.target",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.target)+" ")]}},{key:"item.adjustment",fn:function(ref){
var item = ref.item;
return [(item.problem)?_c('span',[_vm._v(" "+_vm._s(_vm.getAdjustmentRequired(item).toFixed())+_vm._s(item.unit)+" ")]):_vm._e()]}}],null,false,4175667458)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }